import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  sectionName,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'FAQ',
    // paragraph: 'A selection'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="faq"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-xs text-color-high">
                    What is The Aurora Mint?
                   </p>
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    A collection of 200 unique gems. Every trait is hand drawn and every combination handpicked! 
                   </p>
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                   <a href="https://exchange.art/collections/The%20Aurora%20Project%20" target="_blank">Listing on Exchange.art</a>
                   </p>
                  </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    How Many will be minted?
                   </p> 
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    Aurora Gems total Supply 200
                   </p>                 
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                  Which wallet can be used?
                  </p>
                  <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  We recommend Phantom Wallet but you can check <a href="https://exchangeart.zendesk.com/hc/en-us/articles/4409531170449-Which-wallet-s-can-I-use-to-purchase-an-NFT-" target="_blank">Exchange.art</a> to see if your preferred wallet is supported. 
                   </p>                
                  </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    What is the cost?
                   </p>      
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  PreSale list price for the first 40 is 1 Sol. 
                  </p> 
                  <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  The final 160 will be released in small batches for auction with an opening bid of 2 Sol. 
                   </p>               
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                  Tokenomics.
                  </p>
                  <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  Final supply 200. 
                   </p>     
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  PreSale: 40
                   </p>  
                   <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                   Auction: 160 in small batches
                   </p>     
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Diana Rynzhuk</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                  Do I own the artwork?
                  </p>
                  <p className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  Yes, you will be the sole owner and be given full intellectual properties. We DO NOT authorize use for promoting, shilling or selling other NFTs/crypto.
                   </p>  
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Ben Stafford</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;